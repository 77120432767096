<template>
  <div style="width: 100%; height: 100%;background: #fff;">
    <el-form :inline="true"  @input="change($event)" :model="recordsForm" ref="recordsForm">
      <el-form-item label="手机号码" prop="phone_number" style="margin-left:30px">
        <el-input clearable  v-model="recordsForm.phone_number" @keyup.enter.native="submitSearch" placeholder="请输入"/>
      </el-form-item>
      <el-form-item label="回复内容" prop="content" >
        <el-input class="inputWidth" clearable v-model="recordsForm.content" @keyup.enter.native="submitSearch" placeholder="请输入"/>
      </el-form-item>
      <el-form-item>
          <el-button icon="el-icon-search" v-preventReClick @click="submitSearch" type="primary">查询</el-button>
          <el-button icon="el-icon-refresh" v-preventReClick @click="resetForm('recordsForm')">重置</el-button>
      </el-form-item>
      <el-divider></el-divider>
    <el-table 
        :data="records" 
        border  
        row-key="id" 
        :header-cell-style="headClass" 
        id="table" v-loading="pictLoading" 
        element-loading-background="rgb(255,255,255))"
        element-loading-text="数据加载中"
        element-loading-spinner="el-icon-loading">
      <el-table-column prop="phone_number" label="手机号码"  align="center"></el-table-column>
      <el-table-column prop="content" label="用户回复内容"   align="center"></el-table-column>
      <el-table-column prop="create_at" label="回复时间"  align="center"></el-table-column>
      <el-table-column prop="sent_content" label="回复发送内容"  align="center"></el-table-column>
    </el-table>
    <div>
      <pagination
        v-show="total>0"
        :total="total"
        :page.sync="recordsForm.current_page"
        :limit.sync="recordsForm.page_size"
        @pagination="getSmsRecord"
      />
    </div>
  </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
       pictLoading:false,
      records: [],
       total: 0, 
      recordsForm:{
          phone_number:'',
          content:'',
          page_size: 10, 
          current_page: 1,
      },
    }
  },
  computed: {
  },
  created() {
    this.getSmsRecord();
  },
  methods: {

     headClass() {
      return 'background: #f5f5f7;border-bottom:none;color:rgba(0,0,0,.85)'
      },

    change(e){
      this.$forceUpdate()
    },

    async getSmsRecord() {
      this.pictLoading = true
      let params = {
          ...this.recordsForm
        };
      const res = await this.$http.get("/message_receive_records/get", params);
      this.pictLoading = false
      if(res.data.code == 0){
        this.records = res.data.data.records;
        this.total = res.data.data.total;
      }else{
         errorAlert(res.data.msg)
      }
    },

    submitSearch(){ 
       this.recordsForm.current_page=1
        this.getSmsRecord();
        },

    resetForm(formName) {
        this.$refs[formName].resetFields();
        this.submitSearch();
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item{
    margin-top: 20px;
    margin-right: 20px !important;
}
.inputWidth{
    width: 350px !important;
}

.el-pagination {
  background-color: #fff;
  width: 100%;
}
/deep/.ui-text-btns {
  span {
    margin-left: 4px !important;
  }
  .el-link {
    margin-left: 10px;
  }
}
.el-table{
  color: #000;
   margin: 20px 0px 0 -6px;
}
.el-divider--horizontal{
  background: #EBEEF5;
}
</style>